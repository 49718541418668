import type { CSSProperties, PropsWithChildren, ReactElement } from 'react'
import type { GalleryProps, RenderImageProps } from 'react-photo-gallery'
import { List, fromJS } from 'immutable'
import { useCallback, useMemo, useRef, useState } from 'react'
import PhotoGallery from 'react-photo-gallery'
import cc from 'classcat'
import loadable from '@loadable/component'

import { useTranslate } from '../../../../../utils/translate'
import Carousel from './Carousel'
import LazyImage from '../../../../LazyImage'
import Lightbox from '../../../Lightbox'
import Portal from '../../../Portal'

const SettingsLayer = loadable(() => import(/* webpackChunkName: "editor" */ '../../SettingsLayer'))
const SettingsForm = loadable(() => import(/* webpackChunkName: "editor" */ './ImageGallerySettings'))

interface CustomPhotoProps extends LinkProps {
  originalWidth: number
  originalHeight: number
}

interface LinkProps {
  readonly link?: string
  readonly opentab?: boolean
}

interface ExtraGalleryProps {
  editorView?: boolean
}

export default function ImageGalleryPlugin({
  config,
  data = fromJS({
    images: [],
  }),
  editorMode,
  editorView,
  onEdit,
  onCancel,
  onDataChange,
  onSave,
}: Readonly<WorkspacePluginProps>): ReactElement | null {
  const [isBusy, setIsBusy] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  const isEmpty = data.get('images', List()).size === 0

  const t = useTranslate('interface')

  const photos = data
    .get('images', List())
    .toJS()
    .sort((a, b) => a.position - b.position)
    .map((image) => ({
      src: config?.imageUrl?.(image.src),
      originalWidth: image.width,
      originalHeight: image.height,
      width: image.width,
      height: image.height,
      alt: image.alt || '',
      link: image.link || '',
      opentab: image.opentab,
    }))

  if (editorView) {
    function handleCancel() {
      setIsBusy(false)
      onCancel()
    }

    return (
      <div
        className={cc([
          {
            'dali-grid-element-highlighted': editorMode === 'edit',
            'dali-plugin-imagegallery-busy': isBusy,
          },
        ])}
        ref={ref}
      >
        {isBusy && (
          <div className="dali-plugin-imagegallery-busy-indicator" data-testid="imageGalleryBusyIndicator">
            <span />
          </div>
        )}
        {isEmpty ? (
          <div className="dali-grid-element-placeholder">
            <span className="dali-plugin-imagegallery-placeholder" />
            <button className="dali-plugin-imagegallery-placeholder-button-add" onClick={() => onEdit()}>
              {t('components.imageGalleryComponent.addImagesButton.label')}
            </button>
          </div>
        ) : (
          <Gallery photos={photos} editorView />
        )}
        <SettingsLayer
          open={editorMode === 'edit'}
          virtualRef={ref}
          side="top"
          align="center"
          sideOffset={-600}
          onEscapeKeyDown={handleCancel}
          className="dali-settingslayer-imagegallery"
        >
          {({ renderLayout }) => (
            <SettingsForm
              config={config}
              data={data}
              onDataChange={onDataChange}
              onSave={onSave}
              onCancel={handleCancel}
              onBusyStateChange={setIsBusy}
              renderLayout={renderLayout}
            />
          )}
        </SettingsLayer>
      </div>
    )
  }

  return isEmpty ? null : <Gallery photos={photos} />
}

ImageGalleryPlugin.actionBarButtons = { edit: true }

function Gallery({
  photos,
  editorView,
  ...props
}: Readonly<GalleryProps<CustomPhotoProps> & ExtraGalleryProps>): ReactElement {
  const offsetMargin = `-${props.margin || 2}px`

  const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const photosForCarousel = useMemo(() => {
    return photos.filter((photo) => !photo.link)
  }, [photos])

  const openLightbox: GalleryProps['onClick'] = useCallback(
    (_event, { photo }) => {
      if (editorView || photo.link) return

      const indexInCarousel = photosForCarousel.findIndex((p) => p.src === photo.src)

      setCurrentImageIndex(indexInCarousel)
      setViewerIsOpen(true)
    },
    [editorView, photosForCarousel],
  )

  const closeLightbox = () => {
    setCurrentImageIndex(0)
    setViewerIsOpen(false)
  }

  return (
    <div style={{ margin: offsetMargin }}>
      <PhotoGallery {...props} photos={photos} renderImage={renderImage} onClick={openLightbox} />

      <Portal>
        <Lightbox show={viewerIsOpen} onClose={closeLightbox} fullscreen>
          <Carousel photos={photosForCarousel} initialIndex={currentImageIndex} />
        </Lightbox>
      </Portal>

      <noscript>
        {photos.map((photo, index) => (
          <WithLink link={photo.link} opentab={photo.opentab} key={index}>
            <img key={index} src={`${photo.src}&width=300&height=300`} alt={photo.alt} onError={handleImageError} />
          </WithLink>
        ))}
      </noscript>
    </div>
  )
}

function renderImage(props: RenderImageProps<CustomPhotoProps> & { key: string }): ReactElement {
  const { index, onClick, photo, margin, direction, top, left } = props
  const style: CSSProperties = { margin, display: 'block', width: photo.width, height: photo.height }

  if (direction === 'column') {
    style.position = 'absolute'
    style.left = left
    style.top = top
  }

  function handleClick(event) {
    if (onClick) onClick(event, { index })
  }

  return (
    <WithLink link={photo.link} opentab={photo.opentab} key={index}>
      <LazyImage
        src={photo.src}
        width={photo.originalWidth}
        height={photo.originalHeight}
        alt={photo.alt}
        style={style}
        nofallback
        onClick={handleClick}
        onError={handleImageError}
      />
    </WithLink>
  )
}

export function WithLink({ link, opentab, children }: PropsWithChildren<LinkProps>): ReactElement {
  if (!link) return children as ReactElement

  const target = opentab ? '_blank' : undefined
  const rel = opentab ? 'noopener noreferrer' : undefined

  return (
    <a href={link} target={target} rel={rel}>
      {children}
    </a>
  )
}

function handleImageError(image) {
  if (!image.target.src.includes('&reload=1')) image.target.src = `${image.target.src}&reload=1`
}
