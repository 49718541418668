import type { ReactElement } from 'react'

import Contact from './Contact'
import CountrySelection from '../../CountrySelection'
import FooterPages from './FooterPages'
import LegalPages from './LegalPages'
import Logistics from './Logistics'
import NewsletterForm from '../../templateComponents/NewsletterForm'
import Payments from './Payments'
import SocialMedia from './SocialMedia'

export default function Footer({ footerPages, legalPages, shop, t }: FooterThemeComponentProps): ReactElement {
  const { footerLayout, paymentLogos, shippingLogos, socialNetworks, contact, copyright } = shop.userSettings

  return (
    <footer className="section footer">
      <div className="wrapper">
        <NewsletterForm className="newsletter-form">
          <label className="newsletter-form-label" htmlFor="newsletter-form-field">
            {t('components.newsletterSubscriptionComponent.title')}
          </label>
          <div className="newsletter-form-register">
            <input
              className="newsletter-form-field"
              id="newsletter-form-field"
              name="email"
              type="email"
              required
              placeholder={t('components.newsletterSubscriptionComponent.emailAddressInputField.placeholder')}
            />
            <button className="newsletter-form-button" type="submit">
              {t('components.newsletterSubscriptionComponent.submitButton.label')}
            </button>
          </div>
        </NewsletterForm>
        <div className={`footer-${footerLayout.variant}`}>
          <FooterSection section="legalPages" hasContent={legalPages?.length} footerLayout={footerLayout}>
            <LegalPages legalPages={legalPages} />
          </FooterSection>

          <FooterSection
            section="footerPages"
            hasContent={Object.values(footerPages).some((value) => value.isVisible)}
            footerLayout={footerLayout}
          >
            <FooterPages footerPages={footerPages} />
          </FooterSection>

          {footerLayout?.variant === 'column' &&
          footerLayout.footerSections?.payments?.isVisible !== false &&
          footerLayout.footerSections?.shipping?.isVisible !== false &&
          (paymentLogos.length || shippingLogos.length) ? (
            <div className="footer-shared-column">
              <FooterSection section="payments" hasContent={paymentLogos?.length} footerLayout={footerLayout}>
                <Payments paymentLogos={paymentLogos} t={t} />
              </FooterSection>

              <FooterSection section="shipping" hasContent={shippingLogos?.length} footerLayout={footerLayout}>
                <Logistics shippingLogos={shippingLogos} />
              </FooterSection>
            </div>
          ) : (
            <>
              <FooterSection section="payments" hasContent={paymentLogos?.length} footerLayout={footerLayout}>
                <Payments paymentLogos={paymentLogos} t={t} />
              </FooterSection>

              <FooterSection section="shipping" hasContent={shippingLogos?.length} footerLayout={footerLayout}>
                <Logistics shippingLogos={shippingLogos} />
              </FooterSection>
            </>
          )}

          {footerLayout?.variant === 'column' &&
          footerLayout.footerSections?.socialMedia?.isVisible &&
          footerLayout.footerSections?.contact?.isVisible ? (
            <div className="footer-shared-column">
              <FooterSection
                section="socialMedia"
                hasContent={Object.entries(socialNetworks).some(([key, value]) => key.endsWith('Selected') && value)}
                footerLayout={footerLayout}
              >
                <SocialMedia socialNetworks={socialNetworks} />
              </FooterSection>

              <FooterSection
                section="contact"
                hasContent={Object.entries(contact).some(
                  ([key]) => key.endsWith('Selected') && contact[key.replace('Selected', '')],
                )}
                footerLayout={footerLayout}
              >
                <Contact contact={contact} />
              </FooterSection>
            </div>
          ) : (
            <>
              <FooterSection
                section="socialMedia"
                hasContent={Object.entries(socialNetworks).some(([key, value]) => key.endsWith('Selected') && value)}
                footerLayout={footerLayout}
              >
                <SocialMedia socialNetworks={socialNetworks} />
              </FooterSection>
              <FooterSection
                section="contact"
                hasContent={Object.entries(contact).some(
                  ([key]) => key.endsWith('Selected') && contact[key.replace('Selected', '')],
                )}
                footerLayout={footerLayout}
              >
                <Contact contact={contact} />
              </FooterSection>
            </>
          )}
        </div>
        <CountrySelection>
          {(shopCountries, setSellingCountry, shopSellingCountry) => (
            <div className="delivery-country-selection">
              <label className="delivery-country-selection-label" htmlFor="country-selection">
                {t('components.bottomBar.footerSettings.countrySelection.label')}
              </label>
              <div className="delivery-country-dropdown">
                <select
                  id="country-selection"
                  value={shopSellingCountry || ''}
                  onChange={({ target }) => setSellingCountry(Number(target.value))}
                >
                  {shopCountries.map(({ countryId, name }) => (
                    <option key={countryId} value={countryId}>
                      {name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}
        </CountrySelection>
        {copyright?.active && <p className="copyright">{copyright.text}</p>}
      </div>
    </footer>
  )
}

function FooterSection({ section, children, hasContent, footerLayout }): ReactElement | null {
  const isVisible = footerLayout.footerSections?.[section]?.isVisible !== false
  const sectionHeadline = footerLayout?.footerSections?.[section]?.title
  const titleSelected = footerLayout?.footerSections?.[section]?.titleSelected

  if (!isVisible || !hasContent) return null

  return (
    <div>
      {footerLayout?.variant === 'column' && titleSelected && (
        <span className="footer-section-headline">{sectionHeadline}</span>
      )}
      {children}
    </div>
  )
}
