import type { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRef } from 'react'
import Immutable from 'immutable'
import cc from 'classcat'
import loadable from '@loadable/component'

import {
  NEWEST_DESC,
  ON_SALE,
  OUT_OF_STOCK,
  POSITION_ASC,
  PRICE_ASC,
  PRICE_DESC,
  pagingDefaults,
  sortingDefaults,
} from '../../../../../utils/pageAndSort'
import { getPlain } from '../../../../../store/utils'
import { setProductsForCategoryAsync } from '../../../../../store/actions'
import { useTranslate } from '../../../../../utils/translate'
import CategoryContent from '../../../CategoryContent'
import useEffectExceptMount from '../../../../../utils/hooks/useEffectExceptMount'

const SettingsLayer = loadable(() => import(/* webpackChunkName: "editor" */ '../../SettingsLayer'))
const SettingsForm = loadable(() => import(/* webpackChunkName: "editor" */ './CategorySettings'))

export type ProductDataType = {
  products: Frontend.Product[]
  totalNumberOfProducts?: number
  defaultSort?: string
  categoryType: Core.CategoryType
  sort?: string
  categoryId?: string
  facets?: Core.Facets
}

type CategoryPluginData = Immutable.Map<'categoryId' | 'imageSize' | 'pageSize', string | number>
type CategoryPluginProps = {
  editorView: boolean
  editorMode: 'view' | 'edit'
  data: CategoryPluginData
  onSave: () => any
  onCancel: () => any
  onEdit: () => any
  onDataChange: () => any
}

export default function CategoryPlugin({
  editorView,
  editorMode,
  data = Immutable.fromJS({}),
  onSave,
  onCancel,
  onEdit,
  onDataChange,
}: Readonly<CategoryPluginProps>): ReactElement {
  const ref = useRef<HTMLDivElement>(null)

  const dispatch = useDispatch<GlobalDispatch>()

  const t = useTranslate('shop')

  const categoryId = data.get('categoryId') as string
  const pageSize = data.get('pageSize') as number

  const dataWithFixedCategoryId = useSelector<State, State>((state) =>
    data.update('categoryId', (id: string) => (state.get('categoryProductData') ? id : '')),
  )
  const productData: ProductDataType = useSelector<State, ProductDataType>((state) =>
    getPlain<ProductDataType>(state.getIn(['categoryProductData', categoryId], { products: [] })),
  )

  const totalNumberOfProducts = productData.totalNumberOfProducts || 0

  useEffectExceptMount(() => {
    if (editorMode === 'edit')
      dispatch(
        setProductsForCategoryAsync(categoryId, {
          page: pagingDefaults.page,
          resultsPerPage: pageSize,
          sort: sortingDefaults.sort,
        }),
      )
  }, [categoryId, pageSize])

  const pluginActiveClasses = cc([
    'dali-plugin-category',
    {
      'dali-grid-element-highlighted': editorMode === 'edit',
    },
  ])

  const isSmartCategory = productData.categoryType === 'SMART'
  // smart categories don't have the position-asc sort option
  const sortingOptions = {
    ...PRICE_ASC,
    ...PRICE_DESC,
    ...(isSmartCategory ? {} : POSITION_ASC),
    ...NEWEST_DESC,
    ...ON_SALE,
    ...OUT_OF_STOCK,
  }

  return (
    <div className={pluginActiveClasses} ref={ref}>
      <SettingsLayer
        open={editorMode === 'edit'}
        virtualRef={ref}
        side="right"
        align="start"
        onEscapeKeyDown={onCancel}
        className="dali-settingslayer-category"
      >
        {({ renderLayout }) => (
          <SettingsForm data={dataWithFixedCategoryId} {...{ onDataChange, onCancel, onSave, renderLayout }} />
        )}
      </SettingsLayer>
      <CategoryContent
        {...{ categoryId, pageSize, t, productData, sortingOptions, categoryData: dataWithFixedCategoryId }}
      />
      {totalNumberOfProducts === 0 && editorView && (
        <div className="dali-grid-element-placeholder">
          <span className="dali-plugin-collection-placeholder" />
          <button className="dali-plugin-collection-placeholder-button-add" onClick={onEdit}>
            {t('components.collectionComponent.addCollectionButton.label')}
          </button>
        </div>
      )}
    </div>
  )
}

CategoryPlugin.actionBarButtons = {
  save: false,
  edit: true,
}
