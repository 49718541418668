import type { ReactElement } from 'react'

type Props = {
  searchData: Frontend.SearchData
} & TranslateProps

export default function SearchSortingForm({ t, searchData }: Readonly<Props>): ReactElement {
  return (
    <label className="sorting-dropdown">
      <span>{t('components.productListComponent.sortingDropdown.label')}</span>
      <select
        value={searchData.sortings.find((s) => s.active)?.sorting}
        onChange={(e) => searchData.updateSorting(e.target.value)}
      >
        {searchData.sortings.map((sorting) => (
          <option value={sorting.sorting} key={sorting.label}>
            {t('components.productListComponent.sortingDropdown.options.' + sorting.label)}
          </option>
        ))}
      </select>
    </label>
  )
}
