import type { ReactElement } from 'react'
import cc from 'classcat'

import { pagingDefaults } from '../../../utils/pageAndSort'
import NoResults from '../epages.base/NoResults'
import ProductListItem from '../../templateComponents/ProductListItem'
import ScrollToTopButton from '../../ScrollToTopButton'
import SearchSortingForm from '../epages.base/SearchSortingForm'
import ToolbarTop from '../../templateComponents/ToolbarTop'
import useLazyLoadMore from '../../../utils/hooks/useLazyLoadMore'

export default function Search({
  searchData,
  trackProductClick,
  onScrollIntoView,
  loadedPageCount,
  loadMoreProducts,
  showScrollButton,
  isBusy,
  showLoadMoreButton,
  t,
}: SearchThemeComponentProps): ReactElement {
  const inViewRef = useLazyLoadMore(pagingDefaults.resultsPerPage, searchData.products.length, loadMoreProducts)

  return (
    <div className="search">
      <section className="section">
        <div className="wrapper">
          <div className="content">
            <h1>
              {searchData.queryString
                ? t('components.productSearchComponent.resultsCard.resultsState.title', {
                    queryString: searchData.queryString,
                  })
                : t('components.productSearchComponent.resultsCard.noQueryState.title')}
            </h1>

            {searchData.products.length > 0 && searchData.queryString ? (
              <>
                <ToolbarTop className="toolbar-top" onScrollIntoView={onScrollIntoView}>
                  <SearchSortingForm searchData={searchData} t={t} />
                </ToolbarTop>

                <div className="product-list grid-col-m">
                  {searchData.products.map((product, productIndex) => (
                    <ProductListItem
                      key={product.productId}
                      product={product}
                      productIndex={productIndex}
                      inViewRef={inViewRef(productIndex)}
                      trackProductClick={trackProductClick}
                    />
                  ))}
                </div>

                {showLoadMoreButton && (
                  <div className="toolbar-bottom">
                    {loadedPageCount === 1 ? (
                      <button
                        type="button"
                        className={cc(['show-more-button', { pending: isBusy }])}
                        disabled={isBusy}
                        onClick={loadMoreProducts}
                      >
                        {t('components.productSearchComponent.resultsCard.showMoreButton.label')}
                      </button>
                    ) : (
                      isBusy && <button disabled type="button" className="button-spinner-only pending" />
                    )}
                  </div>
                )}
              </>
            ) : (
              <NoResults t={t} />
            )}

            {showScrollButton && <ScrollToTopButton />}
          </div>
        </div>
      </section>
    </div>
  )
}
