import { Anchor, Arrow, Close, Content, Portal, Root, Trigger } from '@radix-ui/react-popover'
import type { ReactElement, RefObject } from 'react'
import cc from 'classcat'

import type { PopoverProps } from './types'

export function Popover({
  children,
  anchor,
  trigger,
  open,
  side = 'bottom',
  align = 'center',
  sideOffset = 10,
  alignOffset = 0,
  className,
  variant = 'light',
  keepOpenOnClick = true,
  collisionPadding = 10,
  onOpenChange,
  virtualRef,
  usePortal = false,
  hideArrow = false,
  avoidCollisions = false,
  onEscapeKeyDown,
  onPointerDownOutside,
  onOpenAutoFocus,
  onMouseDown,
  draggable = false,
  onKeyDown,
  zIndex = 1,
}: Readonly<PopoverProps>): ReactElement {
  const defaultAnchor =
    !anchor && !trigger && !virtualRef ? (
      <div
        data-testid="popover-default-anchor"
        style={{ position: 'fixed', bottom: '40px', left: '50%', transform: 'translateX(-50%)' }}
      />
    ) : null

  const content = (
    <Content
      data-testid="popover-content"
      className={cc([
        className,
        `ep-popover ep-popover-${variant}`,
        {
          'ep-popover-draggable': draggable,
          'ep-popover-no-pointer-events': draggable,
        },
      ])}
      side={side}
      align={align}
      alignOffset={alignOffset}
      avoidCollisions={avoidCollisions}
      collisionPadding={collisionPadding}
      arrowPadding={50}
      sideOffset={sideOffset}
      onMouseDown={onMouseDown}
      onEscapeKeyDown={onEscapeKeyDown}
      onPointerDownOutside={onPointerDownOutside}
      onOpenAutoFocus={onOpenAutoFocus}
      onKeyDown={onKeyDown}
      style={{ zIndex }}
    >
      {keepOpenOnClick ? children : <Close data-testid="popover-close">{children}</Close>}
      {!hideArrow && <Arrow data-testid="popover-arrow" className="ep-popover-arrow" />}
    </Content>
  )

  return (
    <Root onOpenChange={onOpenChange} open={open}>
      {anchor && <Anchor data-testid="popover-anchor">{anchor}</Anchor>}
      {defaultAnchor && (
        <Anchor data-testid="popover-default-anchor" asChild>
          {defaultAnchor}
        </Anchor>
      )}
      {!anchor && virtualRef && (
        <Anchor data-testid="popover-virtual-anchor" virtualRef={virtualRef as RefObject<HTMLElement>} asChild />
      )}
      {trigger && (
        <Trigger data-testid="popover-trigger" asChild>
          {trigger}
        </Trigger>
      )}
      {children && (usePortal ? <Portal data-testid="popover-portal">{content}</Portal> : content)}
    </Root>
  )
}
