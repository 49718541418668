import type { ReactElement } from 'react'
import { fromJS } from 'immutable'
import { useContext, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import loadable from '@loadable/component'

import { PageOrCategoryContext } from '../../views/Category'
import { pagingDefaults, sortingDefaults } from '../../utils/pageAndSort'
import { previewCategorySettings, setProductsForCategoryAsync, updateCategorySettings } from '../../store/actions'

const SettingsLayer = loadable(() => import(/* webpackChunkName: "editor" */ './Workspace/SettingsLayer'))
const CategorySettings = loadable(
  () => import(/* webpackChunkName: "editor" */ './Workspace/plugins/category/CategorySettings'),
)

export default function CategoryPageSettingsLayer({ t }: TranslateProps): ReactElement {
  const [showSettingsLayer, setShowSettingsLayer] = useState(false)
  const pageOrCategory = useContext(PageOrCategoryContext)
  const dispatch = useDispatch<GlobalDispatch>()
  const virtualRef = useRef<HTMLButtonElement>(null)
  const initialImageSize = useRef<Core.Page['settings']['imageSize']>(
    pageOrCategory.getIn(['settings', 'imageSize'], 'M'),
  )
  const data = fromJS({
    imageSize: pageOrCategory.getIn(['settings', 'imageSize'], 'M'),
  })

  const onDataChange = (data: ImmutableMap) => {
    dispatch(previewCategorySettings({ imageSize: data.get('imageSize') }, pageOrCategory.get('categoryId')))
    dispatch(
      setProductsForCategoryAsync(pageOrCategory.get('categoryId'), {
        page: pagingDefaults.page,
        resultsPerPage:
          {
            S: 15,
            M: 12,
            L: 10,
          }[data.get('imageSize')] || pagingDefaults.resultsPerPage,
        sort: sortingDefaults.sort,
      }),
    )
  }
  const onCancel = () => {
    dispatch(previewCategorySettings({ imageSize: initialImageSize.current }, pageOrCategory.get('categoryId')))
    setShowSettingsLayer(false)
  }
  const onSave = (data: ImmutableMap) => {
    initialImageSize.current = data.get('imageSize')
    dispatch(
      updateCategorySettings({
        categoryId: pageOrCategory.get('categoryId'),
        isVisible: pageOrCategory.get('isVisible'),
        slug: pageOrCategory.get('slug'),
        title: pageOrCategory.get('title'),
        objectId: pageOrCategory.get('objectId'),
        visibleInNavigation: pageOrCategory.get('visibleInNavigation'),
        facetedSearchCategoryStartDepth: pageOrCategory.get('facetedSearchCategoryStartDepth'),
        facetedSearchShowFacetsOnCategory: pageOrCategory.get('facetedSearchShowFacetsOnCategory'),
        settings: {
          imageSize: data.get('imageSize'),
        },
      }),
    )
    setShowSettingsLayer(false)
  }

  return (
    <div className="dali-grid-element-buttons">
      <button
        ref={virtualRef}
        type="button"
        className="dali-grid-element-edit-button dali-grid-element-edit-button-edit"
        title={t('components.editorWorkspaceComponent.editElementButton.label')}
        onClick={() => setShowSettingsLayer(true)}
      />
      <SettingsLayer
        virtualRef={virtualRef}
        open={showSettingsLayer}
        onEscapeKeyDown={() => setShowSettingsLayer(false)}
      >
        {({ renderLayout }) => <CategorySettings {...{ renderLayout, data, onCancel, onSave, onDataChange }} />}
      </SettingsLayer>
    </div>
  )
}
