import type { Measurable } from '@radix-ui/rect'
import type { ReactElement, RefObject } from 'react'
import { useRef, useState } from 'react'
import cc from 'classcat'
import loadable from '@loadable/component'

import Button from '../../formComponents/Button'
import ButtonRow from '../../formComponents/ButtonRow'

const SettingsLayer = loadable(() => import(/* webpackChunkName: "editor" */ './SettingsLayer'))

export type BlockAnimation = 'none' | 'fade-in' | 'fade-in-left' | 'fade-in-right' | 'fade-in-bottom'

type Props = {
  animation: BlockAnimation
  initialAnimation: BlockAnimation
  handleAnimationChange: (animation: BlockAnimation) => Promise<void>
  handleAnimationReplay: (animation: BlockAnimation) => Promise<void>
  saveAnimationChange: () => void
  setAnimation: (animation: BlockAnimation) => void
} & TranslateProps

export default function BlockAnimationSettings({
  t,
  animation,
  initialAnimation,
  handleAnimationChange,
  handleAnimationReplay,
  saveAnimationChange,
  setAnimation,
}: Readonly<Props>): ReactElement {
  const [showAnimationSettings, setShowAnimationSettings] = useState(false)
  const buttonRef = useRef<HTMLDivElement | null>(null)
  const virtualButtonRef = useRef<HTMLDivElement | null>(null)
  const scopedT = (key: string) => t(`animationButton.settingsLayer.${key}`)

  const saveAnimation = () => {
    setShowAnimationSettings(false)
    saveAnimationChange()
  }

  const cancelHandler = () => {
    setShowAnimationSettings(false)
    setAnimation(initialAnimation)
  }

  const toggleAnimationSettings = () => {
    if (showAnimationSettings) {
      cancelHandler()
    } else {
      setShowAnimationSettings(true)
      if (animation !== 'none') {
        withFixedSettingsLayerPosition(buttonRef, virtualButtonRef, () => handleAnimationReplay(animation))
      }
    }
  }

  const title = scopedT('title')
  const explanation = scopedT('tooltip')
  const body = (
    <>
      <label className="ep-form-row-label" htmlFor="animation">
        {scopedT('animationDropdown.label')}
      </label>
      <select
        className="ep-custom-select"
        name="animation"
        id="animation"
        onChange={(event) =>
          withFixedSettingsLayerPosition(buttonRef, virtualButtonRef, () =>
            handleAnimationChange(event.target.value as BlockAnimation),
          )
        }
        defaultValue={animation}
      >
        <option value="none">{scopedT('animationDropdown.options.noAnimation')}</option>
        <option value="fade-in">{scopedT('animationDropdown.options.fadeIn')}</option>
        <option value="fade-in-left">{scopedT('animationDropdown.options.fadeInLeft')}</option>
        <option value="fade-in-right">{scopedT('animationDropdown.options.fadeInRight')}</option>
        <option value="fade-in-bottom">{scopedT('animationDropdown.options.fadeInBottom')}</option>
      </select>
      <button
        className={cc([
          'ep-settingslayer-block-animation-settings-replay-button',
          { 'ep-button-disabled': animation === 'none' },
        ])}
        onClick={() =>
          withFixedSettingsLayerPosition(buttonRef, virtualButtonRef, () => handleAnimationReplay(animation))
        }
      >
        {scopedT('replayButton.label')}
      </button>
    </>
  )
  const footer = (
    <ButtonRow>
      <Button type="button" variant="cancel" onClick={cancelHandler}>
        {scopedT('cancelButton.label')}
      </Button>
      <Button type="button" onClick={saveAnimation}>
        {scopedT('saveButton.label')}
      </Button>
    </ButtonRow>
  )

  return (
    <>
      <div
        ref={buttonRef}
        className="dali-block-actionbar-button dali-block-actionbar-button-animation"
        title={t('animationButton.label')}
        onClick={() => toggleAnimationSettings()}
      />
      <SettingsLayer
        open={showAnimationSettings}
        className="ep-settingslayer-block-animation-settings"
        virtualRef={virtualButtonRef.current ? virtualButtonRef : buttonRef}
        side="right"
        onEscapeKeyDown={cancelHandler}
      >
        {({ renderLayout }) =>
          renderLayout({
            title,
            explanation,
            body,
            footer,
          })
        }
      </SettingsLayer>
    </>
  )
}

// provide a static virtualRef to the Popover for the duration of the animation
async function withFixedSettingsLayerPosition(
  buttonRef: RefObject<HTMLDivElement | null>,
  virtualButtonRef: RefObject<Measurable | null>,
  callback: () => Promise<void>,
) {
  const currentClientRect = buttonRef.current?.getBoundingClientRect()
  if (currentClientRect) virtualButtonRef.current = { getBoundingClientRect: () => currentClientRect }
  await callback()
  virtualButtonRef.current = null
}
